/**
 * 
 */
import React from "react";
import { useLoaderData } from "react-router-dom";

export const Chiefdom = (props) => {
	const chiefdom = useLoaderData();
	return (
		<>
			<div id="about">
			  <div className="container">
			    <div className="row">
			      <div className="col-xs-12 col-md-6">
			        {" "}
			        <img src={chiefdom.smallImage} className="img-responsive" alt="" />{" "}
			      </div>
			      <div className="col-xs-12 col-md-6">
			        <div className="about-text">
			          <h2>La chefferie</h2>
					  <p dangerouslySetInnerHTML={{ __html: chiefdom ? chiefdom.paragraph : "loading..." }} />
					  {/*
			          <h3>Why Choose Us?</h3>
			          <div className="list-style">
			            <div className="col-lg-6 col-sm-6 col-xs-12">
			              <ul>
			                {about
			                  ? about.Why.map((d, i) => (
			                      <li key={`${d}-${i}`}>{d}</li>
			                    ))
			                  : "loading"}
			              </ul>
			            </div>
			            <div className="col-lg-6 col-sm-6 col-xs-12">
			              <ul>
			                {about
			                  ? about.Why2.map((d, i) => (
			                      <li key={`${d}-${i}`}> {d}</li>
			                    ))
			                  : "loading"}
			              </ul>
			            </div>
			          </div>
					  */}
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div id="footer">
				<div className="container text-center">
		            <p>
		              &copy; Copyright 2024{" "}
		              <a href="#" rel="nofollow">
		              WORLD VOICE GROUP
		              </a>
					</p>
				</div>
			</div>
		</>
	);
};
