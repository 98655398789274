import React from "react";
import { useLoaderData } from 'react-router-dom';
import { Sidebar } from '../sidebar';

export const Discours = () => {
	const datas = useLoaderData();
	console.log(datas); 
	
	return (
	  <div id="bg">
		<div id="articles" className="container">
		  <div className="row">
			<div className="col-lg-9 col-md-8 col-sm-12">
              <div className="hover-bg">
	              <video className="responsive-video" controls>
	                <source src="img/congres/SA-MAJESTE-1.mp4" type="video/mp4" />
	                Your browser does not support the video tag.
	              </video>
              </div>
			</div>
  
			{/* Sidebar */}
			<div className="col-lg-3 col-md-4 col-sm-12 mt-4 mt-md-0">
				<Sidebar />
			</div>
		  </div>
		</div>
  
		{/* Footer */}
		<div id="footer">
		  <div className="container text-center">
			<p>
			  &copy; Copyright 2024{" "}
			  <a href="#" rel="nofollow">
				WORLD VOICE GROUP
			  </a>
			</p>
		  </div>
		</div>
	  </div>
	);
  };