import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useLoaderData } from "react-router-dom";

const initialState = {
  email: "",
  password: "",
};

export const Signin = (props) => {
  const signup = useLoaderData();
  const [{ email, password }, setState] = useState(initialState);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email, password);

    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className=" d-flex justify-content-center">
      <div id = "contact" className="container">

      <div className="col-sm-8 col-md-6 col-lg-12">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Email"
              required
              onChange={handleChange}
            />
          </div>
          <div className="form-group position-relative">
            <input
              type={passwordVisible ? "text" : "password"}
              name="password"
              className="form-control"
              placeholder="Password"
              required
              onChange={handleChange}
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
            </span>
          </div>
          <div className="text-right">
            <button type="submit" className="btn btn-custom btn-lg">
              Sign In
            </button>
          </div>
        </form>
      </div>

      </div>
      
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; Copyright 2024{" "}
            <a href="#" rel="nofollow">
              WORLD VOICE GROUP
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
