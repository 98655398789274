import React from "react";
import { useLoaderData } from 'react-router-dom';

export const About = (props) => {
	const about = useLoaderData();

	const formatText = (text) => {
		return text.split('\n').map((line, index) => (
			<span key={index}>
				{line}
				<br />
			</span>
		));
	};

	return (
		<>
			<div id="about">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-md-6 mb-8">
							<div className="about-text">
								<h2>À propos de notre village</h2>
								{about ? formatText(about.paragraph) : "loading..."}
							</div>
						</div>
						<div className="col-xs-12 col-md-6">
						<img src={about.smallImage} className="img-responsive" alt="" />{" "}
						</div>
					</div>
				</div>
			</div>
			<div id="footer">
				<div className="container text-center">
					<p>
						&copy; Copyright 2024{" "}
						<a href="#" rel="nofollow">
							WORLD VOICE GROUP
						</a>
					</p>
				</div>
			</div>
		</>
	);
};