import React from "react";
import { useLoaderData, Link } from 'react-router-dom';
import { Sidebar } from '../sidebar';

export const Articles = () => {
	const datas = useLoaderData();
	console.log(datas);  // Vérifiez ce que contient datas
  
	return (
	  <div id="bg">
		<div id="articles" className="container">
		  <div className="row">
			<div className="col-lg-9 col-md-8 col-sm-12">
			  <div id="about" className="articles">
				{datas ? (
				  datas.map((article, index) => (
					<div key={index} className="article">
					  <p>{article.category}</p>
					  <h2>{article.title}</h2>
					  <p>{article.description}</p>
					  <Link to={article.linkUrl} className="link">
						 {article.linkText}
						</Link>
					  <p>
						  By <a href="#" onClick={(e) => e.preventDefault()}>{article.author}</a>, {timeAgo(article.date)}
					  </p>
					</div>
				  ))
				) : (
				  "Loading..."
				)}
			  </div>
			</div>
  
			{/* Sidebar */}
			<div className="col-lg-3 col-md-4 col-sm-12 mt-4 mt-md-0">
			<Sidebar 
            />
			</div>
		  </div>
		</div>
  
		{/* Footer */}
		<div id="footer">
		  <div className="container text-center">
			<p>
			  &copy; Copyright 2024{" "}
			  <a href="#" rel="nofollow">
				WORLD VOICE GROUP
			  </a>
			</p>
		  </div>
		</div>
	  </div>
	);
  };
  
  const timeAgo = (date) => {
	const now = new Date();
	const publishedDate = new Date(date);
	const seconds = Math.floor((now - publishedDate) / 1000);
	
	let interval = Math.floor(seconds / 31536000);
	if (interval >= 1) return `${interval} year${interval > 1 ? 's' : ''} ago`;
	
	interval = Math.floor(seconds / 2592000); // mois
	if (interval >= 1) return `${interval} month${interval > 1 ? 's' : ''} ago`;
	
	interval = Math.floor(seconds / 86400); // jours
	if (interval >= 1) return `${interval} day${interval > 1 ? 's' : ''} ago`;
	
	interval = Math.floor(seconds / 3600); // heures
	if (interval >= 1) return `${interval} hour${interval > 1 ? 's' : ''} ago`;
	
	interval = Math.floor(seconds / 60); // minutes
	if (interval >= 1) return `${interval} minute${interval > 1 ? 's' : ''} ago`;
  
	return 'Just now';
  };