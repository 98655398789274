/**
 * 
 */

import {
	Outlet
} from "react-router-dom";
import SmoothScroll from "smooth-scroll";
import { Navigation } from "./navigation.jsx";
import { Header } from "./header";
import JsonData from "../data/data.json";
import "../App.css";
import { useLocation } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
	speed: 1000,
	speedAsDuration: true,
});

const Root = () => {
	const location = useLocation();

	const getHeaderData = () => {
		switch (location.pathname) {
			case "/about":
				return JsonData.Header.about;
			case "/news":
				return JsonData.Header.news;
			case "/congress":
				return JsonData.Header.congress;
			case "/codebaf":
				return JsonData.Header.codebaf;
			case "/chiefdom":
				return JsonData.Header.chiefdom;
			case "/signin":
				return JsonData.Header.signin;
			case "/signup":
				return JsonData.Header.signup;
			case "/presentation":
				return JsonData.Header.presentation;
			case "/discours":
				return JsonData.Header.discours;
			default:
				return JsonData.Header.home;
		}
	};

	return (
		<>
			{/*
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<p>
					Edit <code>src/App.js</code> and save to reload.
				</p>
				<a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
	      			Learn React
				</a>
			</header>
			*/}
			<div>
				<Navigation />
				<Header data={getHeaderData()} />

			</div>
			<div>
				<Outlet />
			</div>
		</>
	);
}

export default Root;