import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";

export const Navigation = (props) => {
  const location = useLocation();

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href={`/`}>
            <img
              src={logo}
              alt="Logo Batoula Foguigo"
              style={{ height: "50px", marginTop: "-15px" }}
            />
          </a>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li className={location.pathname === "/" ? "active" : ""}>
              <a href="/" className="page-scroll">Accueil</a>
            </li>
            <li className={location.pathname === "/about" ? "active" : ""}>
              <a href={`/about`} className="page-scroll">À propos</a>
            </li>
            <li className={location.pathname === "/news" ? "active" : ""}>
              <a href={`/news`} className="page-scroll">Actualités</a>
            </li>
            <li className={location.pathname === "/congress" ? "active" : ""}>
              <a href={`/congress`} className="page-scroll">Congrès Annuel</a>
              <ul className="dropdown">
                {/* <li><a href="/congress/2024">Congrès 2024</a></li> */}
                <li><a href="/congress">Congrès 2023</a></li>
               
              </ul>
            </li>
            <li className={location.pathname === "/chiefdom" ? "active" : ""}>
              <a href={`/chiefdom`} className="page-scroll">Chefferie</a>
            </li>
            <li className={location.pathname === "/codebaf" ? "active" : ""}>
              <a href={`/codebaf`} className="page-scroll">CODEBAF</a>
            </li>
            <li className={location.pathname === "/signin" ? "active" : ""}>
              <a href={`/signin`}>Connexion</a>
            </li>
            <li className={location.pathname === "/signup" ? "active" : ""}>
              <a href={`/signup`}>Inscription</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};