import React from "react";
import { useLoaderData, Link } from 'react-router-dom';
import JsonData from "../data/data.json";


export const Sidebar = () => {
  const data = useLoaderData(); // Récupérer toutes les données

  // Accéder aux données de la barre latérale
  const { recentPosts, archives } = JsonData.Sidebar || {}; 
  console.log("Recent Posts:", recentPosts); 
  console.log("Archives:", archives);        

  return (
    <div className="sidebar">
      <div className="search-bar">
        <input type="text" placeholder="Rechercher..." />
      </div>
      <div className="recent-posts">
        <h3>Articles récents</h3>
        {recentPosts && recentPosts.length > 0 ? (
          recentPosts.map((post, index) => (
            // <div key={index} className="recent-post">
            //   <p>{post.title}</p>
            // </div>

           
            <div key={index} className="recent-post">
            <p>
              <Link to={post.linkUrl} style={{ textDecoration: 'none', color: 'black' }}>
                {post.title}
              </Link>
            </p>
          
          </div>
          ))
        ) : (
          "Aucun article récent"
        )}
      </div>
      <div className="archives">
        <h3>Archives</h3>
        {archives && archives.length > 0 ? (
          archives.map((archive, index) => (
            <div key={index} className="archive">
              <p>{archive.month}</p>
            </div>
          ))
        ) : (
          "Aucune archive"
        )}
      </div>
    </div>
  );
};