import React from "react";
import { useLoaderData } from 'react-router-dom';
import { Sidebar } from '../sidebar';
import JsonData from "../../data/data.json";


export const Presentation = () => {
	const datas = useLoaderData();
	console.log(datas); 
	
	const content = JsonData?.presentation?.content || "Loading...";
	return (
	  <div id="bg">
		<div id="articles" className="container">
		  <div className="row">
			<div className="col-lg-9 col-md-8 col-sm-12">
            <div id="about" className="articles">
				{content ? (
				  <div>
					<p>{content.split('\n').map((line, index) => (
					  <span key={index}>{line}<br /></span>
					))}</p>
				  </div>
				) : (
				  "Loading..."
				)}
			  </div>
			</div>
  
			{/* Sidebar */}
			<div className="col-lg-3 col-md-4 col-sm-12 mt-4 mt-md-0">
			<Sidebar 
            />
			</div>
		  </div>
		</div>
  
		{/* Footer */}
		<div id="footer">
		  <div className="container text-center">
			<p>
			  &copy; Copyright 2024{" "}
			  <a href="#" rel="nofollow">
				WORLD VOICE GROUP
			  </a>
			</p>
		  </div>
		</div>
	  </div>
	);
  };
  