import React from "react";
import JsonData from "../../data/data.json";
import { Gallery } from "../gallery";
import { useLoaderData } from 'react-router-dom';

export const Welcome = (props) => {
	const datas = useLoaderData();
  return (
	<div id="bg" className="col-5">
	    <div id="services" className="text-center">
	        {/* <div className="section-title">
	          <h2>Quelques images et vidéos du Congrès 2023</h2>
	          <p>Installation du nouveau CODEBAF par Sa Majesté</p>
	        </div> */}
            <Gallery data={JsonData.Gallery} />
	     
	    </div>
		<div id="footer">
			<div className="container text-center">
	            <p>
	              &copy; Copyright 2024{" "}
	              <a href="#" rel="nofollow">
	              WORLD VOICE GROUP
	              </a>
				</p>
			</div>
		</div>
	</div>
  );
};


