import React from "react";
import JsonData from "../../data/data.json";
import { useLoaderData } from 'react-router-dom';

export const Congress = (props) => {
	// const datas = useLoaderData();
	// console.log(datas);
  return (
	<>
	    <div id="services" className="text-center">
	      <div className="container">
	        <div className="section-title">
	          <h2>Quelques images et vidéos du Congrès 2023</h2>
	          <p>Installation du nouveau CODEBAF par Sa Majesté</p>
	        </div>
	        <div className="row">
	          <div className="portfolio-items">
	            {JsonData.Congress
	              ? JsonData.Congress.map((d, i) => (
	                  <div
	                    key={`${d.title}-${i}`}
	                    className="col-8 portfolio-item" // Assure-toi que chaque item occupe toute la largeur
	                  >
	                    <div className="hover-bg">
	                      <video width="50%" controls>
	                        <source src={d.largeVideo} type="video/mp4" />
	                        Your browser does not support the video tag.
	                      </video>
	                      <div className="hover-text">
	                        <h4>{d.title}</h4>
	                      </div>
	                    </div>
	                  </div>
	                ))
	              : "Loading..."}
	          </div>
	        </div>
	      </div>
	    </div>
		<div id="footer">
			<div className="container text-center">
	            <p>
	              &copy; Copyright 2024{" "}
	              <a href="#" rel="nofollow">
	              WORLD VOICE GROUP
	              </a>
				</p>
			</div>
		</div>
	</>
  );
};
