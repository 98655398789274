/**
 * 
 */
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { useLoaderData } from "react-router-dom";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Signup = (props) => {
	const signup = useLoaderData();
	
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Inscription</h2>
                <p>
                  Remplir le formulaire ci-dessous pour être informé en temps réel de toutes les activités
				  en rapport avec le village Batoula Foguimgo.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
				  	<label for="basic-url" class="form-label">Nom</label>
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="first_name"
                        className="form-control"
                        placeholder="Nom"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
				  	<label for="basic-url" class="form-label">Nom de famille</label>
                    <div className="form-group">
                      <input
                        type="text"
                        id="lastName"
                        name="last_name"
                        className="form-control"
                        placeholder="Nom de famille"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
				<div className="row">
                  <div className="col-md-12">
				  	<label for="basic-url" class="form-label">Votre adresse email</label>
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
				<div className="row">
                  <div className="col-md-6">
				  	<label for="basic-url" class="form-label">Mot de passe</label>
                    <div className="form-group">
                      <input
                        type="password"
                        id="password1"
                        name="password1"
                        className="form-control"
                        placeholder="Mot de passe"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
				  	<label for="basic-url" class="form-label">Confirmation du mot de passe</label>
                    <div className="form-group">
                      <input
                        type="password"
                        id="password2"
                        name="password2"
                        className="form-control"
                        placeholder="Confirmer mot de passe"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
				<div className="row">
                  <div className="col-md-6">
				  	<label for="basic-url" class="form-label">Date de naissance</label>
                    <div className="form-group">
                      <input
                        type="date"
                        id="birthdate"
                        name="birthdate"
                        className="form-control"
                        placeholder="Date de naissance"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
				<div className="row">
                  <div className="col-md-6">
				  	<label for="basic-url" class="form-label">Pays de naissance</label>
                    <div className="form-group">
                      <input
                        type="text"
                        id="birthCountry"
                        name="birth_country"
                        className="form-control"
                        placeholder="Pays de naissance"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
				<div className="row">
                  <div className="col-md-6">
				  	<label for="basic-url" class="form-label">Ville de naissance</label>
                    <div className="form-group">
                      <input
                        type="text"
                        id="birthCity"
                        name="birth_city"
                        className="form-control"
                        placeholder="Ville de naissance"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
				<div className="row">
                  <div className="col-md-6">
				  	<label for="basic-url" class="form-label">Numero téléphone</label>
                    <div className="form-group">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Numéro téléphone"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
				<div className="row">
                  <div className="col-md-12">
				  	<label for="basic-url" class="form-label">Adresse domicile</label>
                    <div className="form-group">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder=""
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
				<div className="row">
                  <div className="col-md-6">
				  	<label for="basic-url" class="form-label">Ville</label>
                    <div className="form-group">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder=""
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
				  <div className="col-md-6">
  				  	<label for="basic-url" class="form-label">Région / État / Province</label>
                      <div className="form-group">
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          className="form-control"
                          placeholder=""
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                </div>
				<div className="row">
					<div className="col-md-6">
					  	<label for="basic-url" class="form-label">Code postale</label>
	                    <div className="form-group">
	                      <input
	                        type="text"
	                        id="phone"
	                        name="phone"
	                        className="form-control"
	                        placeholder=""
	                        required
	                        onChange={handleChange}
	                      />
	                      <p className="help-block text-danger"></p>
	                    </div>
	                  </div>
					  <div className="col-md-6">
	  				  	<label for="basic-url" class="form-label">Pays</label>
	                      <div className="form-group">
	                        <input
	                          type="text"
	                          id="phone"
	                          name="phone"
	                          className="form-control"
	                          placeholder=""
	                          required
	                          onChange={handleChange}
	                        />
	                        <p className="help-block text-danger"></p>
	                      </div>
	                    </div>
					</div>
                <div className="form-group">
					<label for="basic-url" class="form-label">Laissez un message</label>
					<textarea
					    name="message"
					    id="message"
					    className="form-control"
					    rows="4"
					    placeholder="Message"
					    required
					    onChange={handleChange}>
					</textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Soumettre
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {signup ? signup.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {signup ? signup.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {signup ? signup.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={signup ? signup.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={signup ? signup.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={signup ? signup.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; Copyright 2024{" "}
            <a href="#" rel="nofollow">
            WORLD VOICE GROUP
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
