import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
          Explorez Batoula Foguimgo, un village représentatif de l'Ouest du Cameroun, intégré au groupement Foto. Situé dans le département de Menoua, ce charmant village est entouré de collines verdoyantes et de terres agricoles, où la communauté Bamiléké cultive ses traditions. Batoula Foguimgo est un lieu dynamique, riche en culture, rythmé par des marchés, des festivals et un congrès annuel qui unissent les habitants. Venez découvrir cet espace chaleureux où l'hospitalité et le patrimoine culturel s'entrelacent, créant une ambiance vivante et accueillante pour tous.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
