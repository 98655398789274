import React from 'react';
import ReactDOM from 'react-dom';
import {
	createBrowserRouter,
	RouterProvider,
	Route,
	Link,
    createRoutesFromElements
} from 'react-router-dom';
import './index.css';
import App from './App';
import Root from './components/root.jsx';
import { Articles } from './components/news/articles.jsx';
import { Congress } from './components/congress/congress.jsx';
import { About } from './components/about/about.jsx';
import { Signup } from './components/signup/signup.jsx';
import { Chiefdom } from './components/chiefdom/chiefdom.jsx';
import { Welcome } from './components/welcome/welcome.jsx';
import { Codebaf } from './components/codebaf/codebaf.jsx';
import JsonData from "./data/data.json";
import * as serviceWorker from './serviceWorker';
import { Signin } from './components/signin/signin.jsx';
import { Presentation } from './components/news/presentation.jsx';
import { Discours } from './components/news/discours.jsx';

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path='/' element={<Root />}>
			
			<Route path='/'
				element={<Welcome/>}
				// loader={() => {
				// 	return JsonData.Articles;
				// }}
			/>

			<Route path='news'
				element={<Articles />}
				loader={() => {
					return JsonData.Articles;
				}}
			/>
			<Route
				path='congress'
				element={<Congress />}
				loader={({}) => {
					return JsonData.Congress;
				}}
			/>
			<Route
				path='about'
				element={<About />}
				loader={({}) => {
					return JsonData.About;
				}}
			/>
			<Route
				path='chiefdom'
				element={<Chiefdom />}
				loader={({}) => {
					return JsonData.Chiefdom;
				}}
			/>
			<Route
				path='codebaf'
				element={<Codebaf />}
				loader={({}) => {
					return JsonData.Codebaf;
				}}
			/>
			<Route
				path='signin'
				element={<Signin/>}
				loader={({}) => {
					return JsonData.Signup;
				}}
			/>
			<Route
				path='signup'
				element={<Signup />}
				loader={({}) => {
					return JsonData.Signup;
				}}
			/>
			<Route
				path='presentation'
				element={<Presentation />}
				loader={({}) => {
					return JsonData.Signup;
				}}
			/>
			<Route
				path='discours'
				element={<Discours />}
				loader={({}) => {
					return JsonData.Signup;
				}}
			/>
		</Route>
	)
);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);

/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
!/

/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
